var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", { staticClass: "mb-2" }, [
      _vm._v(
        " " + _vm._s(_vm.$t("applications.city-of-portland-notices")) + " "
      )
    ]),
    _c("div", [
      _c(
        "ul",
        { staticStyle: { "list-style-type": "none" } },
        _vm._l(_vm.$config("applications.pcaDisclosures"), function(
          ref,
          index
        ) {
          var link = ref.link
          var slug = ref.slug
          return _c("li", { key: index }, [
            _c(
              "a",
              {
                staticClass: "disclosure",
                attrs: { href: link, target: "_blank" }
              },
              [_vm._v(_vm._s(_vm.$t("applications." + slug)))]
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }