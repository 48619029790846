<template>
    <div>
        <h4 class="mb-2">
            {{ $t("applications.city-of-portland-notices") }}
        </h4>
        <div>
            <ul style="list-style-type: none">
                <li
                    v-for="({ link, slug }, index) in $config(
                        'applications.pcaDisclosures'
                    )"
                    :key="index"
                >
                    <a class="disclosure" :href="link" target="_blank">{{
                        $t(`applications.${slug}`)
                    }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: "portland-city-notices",
};
</script>

<style scoped>
</style>